import React from "react";
import "./cta.css";

const Cta = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-section">
      <h5>Request Early Access to Get Started</h5>
      <h1>Register today & start exploring the endless possiblities.</h1>
    </div>
    <div className="gpt3__cta-btn">
      <button>Get Started</button>
    </div>
  </div>
);

export default Cta;
