import React from "react";
import "./possibility.css";
import possibility from "../../assets/possibility.png";

const Possibility = () => (
  <div className="gpt3__possibility section-container">
    <div className="gpt3__possibility-woman">
      <img src={possibility} alt="woman with VR headset" />
    </div>
    <div className="gpt3__possibility container_content">
      <div className="gpt3__possibility-container_content-access">
        <h4>Request Early Access to Get Started</h4>
        <h1 className="gradient__text">
          The possibilities are beyond your imagination
        </h1>
        <p>
          Yet bed any for travelling assistance indulgence unpleasing. Not
          thoughts all exercise blessing. Indulgence way everything joy
          alteration boisterous the attachment. Party we years to order allow
          asked of.
        </p>
        <h4>Request Early Access to Get Started</h4>
      </div>
    </div>
  </div>
);

export default Possibility;
